import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  BrowserRouter,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import PageLoading from '@/components/PageLoading';
import { loginAndRegister } from '@/views';
import {
  hasLeftSidePath,
  noLeftSidePath,
  defaultPath,
} from '@/config/path';
import {
  MainLayout,
  DashboardLayout,
} from '@/layouts';
import {
  MainLayoutRoutes,
} from './mainLayout';
import {
  DashboardLayoutRoutes,
} from './dashboardLayout';

const Router = (props) => {
  const {
    getUserInfo,
    getQrCode,
    AccountSet: {
      loading,
    },
  } = props;
  // 重新触发请求
  const [refresh, setRefresh] = useState(true);

  /* eslint-disable */
  useEffect(() => {
    window.localStorage.removeItem('userInfo');
    window.localStorage.removeItem('currentOpen');
    // 先清空 userInfo
    if (window.localStorage.getItem('token') && refresh) {
      setRefresh(false);
      const fetchData = async () => {
        // await clearCache();
        await getUserInfo((data) => {
          getQrCode(data);
        });
      };
      fetchData();
    }
  }, [refresh, getUserInfo]);
  /* eslint-enable */

  if (loading) {
    return <PageLoading />;
  }

  return (
    <BrowserRouter>
      <Switch>
        {/* 登录相关界面 */}
        <Route path="/login" component={loginAndRegister} />
        <Route path="/register" component={loginAndRegister} />

        {/* 主功能页面（带左侧菜单） */}
        <Route
          path={`${hasLeftSidePath}`}
          component={prop => <MainLayout {...prop} chidrenRoutes={MainLayoutRoutes} />}
        />

        {/* 主功能页面（不带菜单） */}
        <Route
          path={`${noLeftSidePath}`}
          component={prop => <DashboardLayout {...prop} chidrenRoutes={DashboardLayoutRoutes} />}
        />

        {window.localStorage.getItem('token') ? <Redirect to={defaultPath} /> : <Redirect to="/login" /> }

        {/* <Route exact path="/" component={loginAndRegister} /> */}

        {/* <Route exact path='/findPw' component={FindPw} /> */}
      </Switch>
    </BrowserRouter>
  );
};

export default connect(({ AccountSet }) => ({
  AccountSet,
}), (
  {
    AccountSet:
    {
      getUserInfo,
      getQrCode,
      clearCache,
    },
  },
) => ({
  getUserInfo,
  getQrCode,
  clearCache,
}))(Router);
