import React, { useState } from 'react';
import { Divider } from 'antd';
import FormInput from './FormInput';
import styles from '../index.module.less';

const CommonLogin = () => {
  // 通用登录方式 0 密码; 1 验证码
  const [selectedCode, setSelectedCode] = useState(0);

  // 改变通用登录方式
  const changeCommonLoginStatus = (code) => {
    setSelectedCode(code);
  };

  return (
    <>
      {/* 登录方式 */}
      <div className={styles.selectLoginStyleFrame}>
        <div
          className={selectedCode === 0 ? styles.selectedStatus : styles.commonStatus}
          onClick={() => { changeCommonLoginStatus(0); }}
        >
          密码登录
        </div>
        <Divider type="vertical" />
        <div
          className={selectedCode === 1 ? styles.selectedStatus : styles.commonStatus}
          onClick={() => { changeCommonLoginStatus(1); }}
        >
          验证码登录
        </div>
      </div>
      {/* 输入框 */}
      <div className={styles.formInputFrame}>
        <FormInput status={selectedCode} />
      </div>
    </>
  );
};

export default CommonLogin;
