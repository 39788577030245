export default function openNewPage(url, target) {
  const domA = document.createElement('a');
  domA.href = url;
  domA.target = target || '_blank';
  // 触发点击
  document.body.appendChild(domA);
  domA.click();
  // 然后移除
  document.body.removeChild(domA);
}
