import request from '@/service';

// 获取已绑定机构列表
export async function getRelationList() {
  return request('/teacher-service/account_info/get_relation_list', { method: 'get' });
}
// 设置当前绑定机构
export async function setCurrentRelation(params) {
  return request('/teacher-service/account_info/set_current_relation', { method: 'post', data: params });
}
