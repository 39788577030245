import 'react-app-polyfill/ie9';
import React from 'react';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import store from './store';
import Router from './router';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ConfigProvider locale={zhCN}>
          <Router />
        </ConfigProvider>
      </Provider>
    </div>
  );
}

export default App;
