import React, { useState, useEffect } from 'react';
import {
  Icon,
  Badge,
  Dropdown,
  Menu,
} from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TriangleLabel from '@/components/Common/TriangleLabel';
import UserUpload from '@/assets/img/userUpload.png';
import openNewPage from '@/utils/openNewPage';
import styles from './index.module.less';
import { getRelationList, setCurrentRelation } from './server';
import Modal from '@/components/Common/Modal';

const UserInfo = (props) => {
  const {
    history,
    tipWordStyle,
    AccountSet: {
      userInfo,
    },
  } = props;
  // 弹窗显示控制
  const [visibleOrg, setVisibleOrg] = useState(false);
  const [relationList, setRelationList] = useState([]);
  useEffect(() => {
    let isMounted = true;
    // 在 useEffect 中发起异步请求获取数据
    getRelationList()
      .then((res) => {
        isMounted && setRelationList(res.data);
      });
    return () => {
      isMounted = false;
    };
  }, []); // 空数组作为第二个参数，表示这个 effect 只会在组件挂载后运行一次
  const switchOrg = () => {
    setVisibleOrg(true);
  };
  const closeModal = () => {
    setVisibleOrg(false);
  };
  const setOrg = (orgId) => {
    setVisibleOrg(false);
    setCurrentRelation({ orgId })
      .then(() => {
        // 刷新页面
        window.location.reload();
      });
  };

  // 登出
  const logout = () => {
    window.localStorage.clear();
    openNewPage('/login', '_self');
  };

  // 跳转个人账户
  const jumpAccount = () => {
    history.push('/dashboard/set');
  };

  // 跳转我的课表
  const jumpMySchedule = () => {
    history.push('/main/mySchedule');
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <div className={styles.userinfoname}> {userInfo.userName}</div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={switchOrg}>
          <Icon type="swap" />切换机构
        </div>
      </Menu.Item>
      {window.location.href.indexOf('dashboard/set') > -1 ? (
        <Menu.Item>
          <div onClick={jumpMySchedule}>
            <Icon type="setting" />我的课表
          </div>
        </Menu.Item>
      ) : (
        <Menu.Item>
          <div onClick={jumpAccount}>
            <Icon type="setting" />个人账号
          </div>
        </Menu.Item>
      )}

      <Menu.Item>
        <div onClick={logout}>
          <Icon type="poweroff" />退出登录
        </div>
      </Menu.Item>
    </Menu>
  );

  // tipWord 样式
  const tipWordUI = {
    marginLeft: '17px',
    ...tipWordStyle,
  };

  // console.log('userinfo', userInfo)

  return (
    <div className={styles.UserInfoContainer}>
      <div className={styles.tipWord} style={tipWordUI}>
        所属机构: {window.localStorage.getItem('orgName') || ''}
      </div>
      <div className={styles.infoPart}>
        {/* <Badge count={20}> */}
        <Badge count={0}>
          <Icon
            type="bell"
            theme="filled"
            style={{ color: '#D6D5E5' }}
          />
        </Badge>
        {/* <Icon
          type="ellipsis"
          className={styles.headerInfoTip}
        /> */}
        <div className={styles.downloadTochContainer}>
          <Dropdown
            overlay={menu}
            getPopupContainer={trigger => trigger.parentNode}
          >
            <div className={styles.downloadTochFrame}>
              <div className={styles.imgPerson}>
                <img src={userInfo ? (userInfo.avatarUrl || UserUpload) : UserUpload} alt="头像" />
              </div>
              <div className={styles.TriangleLabelContainer}>
                <TriangleLabel />
              </div>
            </div>
          </Dropdown>
          <RelationModal
            visibleOrg={visibleOrg}
            closeModal={closeModal}
            relationList={relationList}
            select={setOrg}
          />
        </div>

      </div>
    </div>
  );
};
// 切换机构弹窗
const RelationModal = ({
  visibleOrg, closeModal, relationList, select,
}) => {
  const orgId = window.localStorage.getItem('orgId');
  return (
    <>
        <Modal title="切换机构" labelShow bodyStyle={{ padding: '0px 0px 80px 0px' }} width={460} visible={visibleOrg} closeModal={closeModal} getContainer>
         <div>
          {relationList.map(item => (
            <div className={styles.itemOrg} key={item.orgId}>
              <div>{item.orgName}</div>
              {/* eslint-disable-next-line eqeqeq */}
              {orgId == item.orgId && <div className={styles.currentOrg}><Icon type="check" /></div>}
              {/* eslint-disable-next-line eqeqeq */}
              {orgId != item.orgId
              && <div className={styles.selectOrg} onClick={() => { select(item.orgId); }}>
                    选择
                 </div>}
              {/* <Icon type="check" /> */}
            </div>
          ))}
         </div>
        </Modal>
    </>
  );
};
UserInfo.defaultProps = {
  tipWordStyle: {},
};
UserInfo.propTypes = {
  tipWordStyle: PropTypes.object,
};

export default withRouter(connect(({ AccountSet }) => ({
  AccountSet,
}))(UserInfo));
