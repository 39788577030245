import React from 'react';
import {
  Modal,
} from 'antd';
import PropTypes from 'prop-types';
import styles from './index.module.less';

const CommonModal = (props) => {
  const {
    title,
    footer,
    children,
    width,
    visible,
    closeModal,
    labelShow,
    getContainer,
    bodyStyle,
  } = props;

  // 头部标题
  const TitleComponent = data => (
    <>
      <div className={styles.modalTitleLabel}>
        <div />
      </div>
      <div className={styles.modalTitle}>{data}</div>
    </>
  );

  // 关闭弹窗
  const handleCancel = () => {
    closeModal(false);
  };

  return (
    <Modal
      className={styles.modalContainer}
      getContainer={getContainer}
      visible={visible}
      title={labelShow ? TitleComponent(title) : title}
      width={width}
      // onOk={this.handleOk}
      onCancel={handleCancel}
      footer={footer || null}
      bodyStyle={bodyStyle}
    >
      {children}
    </Modal>
  );
};

CommonModal.defaultProps = {
  title: '',
  footer: null,
  width: 520,
  children: <></>,
  visible: false,
  closeModal: () => {},
  labelShow: false,
  getContainer: false,
  bodyStyle: {},
};
CommonModal.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  footer: PropTypes.element,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  children: PropTypes.element,
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  labelShow: PropTypes.bool,
  getContainer: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool,
  ]),
  bodyStyle: PropTypes.object,
};

export default CommonModal;
