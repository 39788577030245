import React, { Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import PageLoading from '@/components/PageLoading';
import { hasLeftSidePath } from '@/config/path';

// 教务管理
import { ReactComponent as TeachIcon } from '@/assets/img/teachIcon.svg';
// 数据中心
import { ReactComponent as DataCenterIcon } from '@/assets/img/dataCenterIcon.svg';

const lazy = CusComponent => <Suspense fallback={<PageLoading />}>{CusComponent}</Suspense>;
const RedirectTo = path => <Redirect to={path || `${hasLeftSidePath}/mySchedule`} />;

// 排课管理
const ScheduleManagePage = React.lazy(() => import('../../views/main/teach/scheduleManage/list'));

// 排课统计
const ClassstatPage = React.lazy(() => import('../../views/main/dataCenter/classstat'));


export const MainLayoutRoutes = [
  {
    path: `${hasLeftSidePath}/mySchedule`,
    icon: TeachIcon,
    name: '我的课表',
    exact: true,
    component: props => lazy(<ScheduleManagePage {...props} />),
  },
  {
    path: `${hasLeftSidePath}/data`,
    name: '数据统计',
    icon: DataCenterIcon,
    component: props => lazy(<ClassstatPage {...props} />),
  },
  {
    path: '*',
    name: '404',
    component: () => RedirectTo(),
  },
];

export const otherPath = [];
