import React from 'react';
import classnames from 'classnames';
import SiderTopIcon from './SiderTopIcon';
import SiderMenu from './SiderMenu';
import styles from './index.module.less';

const Sider = (props) => {
  const { data } = props;

  // console.log(222, data);

  return (
    <div className={classnames(styles.commonSider, 'commonScrollStyle')}>
      <SiderTopIcon data={data} />
      <div className={styles.commonSiderMenuContainer}>
        <SiderMenu routeData={data} />
      </div>
    </div>
  );
};

export default Sider;
