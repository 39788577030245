import React, { useEffect, useState } from 'react';
import { Menu, Icon } from 'antd';
import { Link } from 'react-router-dom';
// import { MainLayoutRoutes } from '@/router/mainLayout';
// import {
//   basePath,
//   defaultPath,
// } from '@/config/path';
import styles from './index.module.less';

const { SubMenu } = Menu;

const SiderMenu = (props) => {
  const {
    routeData
  } = props;
  // 菜单打开项
  const [currentOpenKey, setCurrentOpenKey] = useState('');

  /* eslint-disable */
  useEffect(() => {
    const openKey = routeData && routeData.length && routeData.filter(item => item.path !== '/' && item.path !== '*' && window.location.pathname.indexOf(item.path) !== -1);
    setCurrentOpenKey(openKey[0] ? openKey[0].path : '');
  }, [JSON.stringify(routeData)]);
  /* eslint-enable */

  // 获取当前选中项
  const getSelectKey = (arr, key) => {
    if (arr && arr.length) {
      arr.forEach((item) => {
        if (window.location.pathname === item.path) {
          key = item.hidden ? item.belongPath : item.path;
        }
        if (item.routes && item.routes.length && !key) {
          key = getSelectKey(item.routes, key)
        }
      })
    }
    return key;
    // return saveArr[0].hidden ? saveArr[0].belong : saveArr[0].path;
  };

  // 打开菜单项
  const handleOpenChange = (changeKey) => {
    setCurrentOpenKey(changeKey[1]);
  };

  // 生成菜单
  const produceMenuContent = (data) => {
    // console.log(2222, data)
    return data.map((item) => {
      // console.log(333, item)
      if (item.path !== '/' && item.path !== '*') {
        const linkAttr = {
          to: item.path,
        };
        if (item.target) {
          linkAttr.target = item.target || '_self';
        }
        if (!item.hidden) {
          if (item.routes && item.routes.length) {
            return (
              <SubMenu
                key={item.path}
                title={
                  <span>
                    {item.icon ? (
                      <Icon
                        component={item.icon}
                        style={{
                          color: currentOpenKey === item.path ? '#fff' : '#979BF7',
                          marginRight: '31px',
                          fontSize: '20px',
                        }}
                      />
                    ) : (
                      <span className={styles.defaultIconStyle} />
                    )}
                    <span className={styles.menuName}>{item.name}</span>
                  </span>
                }
              >
                {produceMenuContent(item.routes)}
              </SubMenu>
            );
          }

          return (
            <Menu.Item key={item.path}>
              <Link {...linkAttr}>
                {window.location.pathname.indexOf(item.path) > -1 && <div className={styles.selectedStatus} />}
                {item.icon ? (
                  <Icon
                    component={item.icon}
                    style={{
                      color: window.location.pathname.indexOf(item.path) > -1 ? '#fff' : '#979BF7',
                      marginRight: '31px',
                      fontSize: '20px',
                    }}
                  />
                ) : (
                  <span className={styles.defaultIconStyle} />
                )}
                <span className={styles.menuName}>{item.name}</span>
              </Link>
            </Menu.Item>
          );
        }
      }
      return true;
    });
  };

  return (
    <>
      <Menu
        onOpenChange={handleOpenChange}
        theme="dark"
        mode="inline"
        selectedKeys={getSelectKey(routeData, '')}
        openKeys={[currentOpenKey]}
      >
        {produceMenuContent(routeData)}
      </Menu>
    </>
  );
};

export default SiderMenu;
