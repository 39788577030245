import React, { useState } from 'react';
import QRrelation from './QRrelation';
import CommonLogin from './CommonLogin';
import QRLogin from './QRLogin';

const Login = () => {
  // 用户登录方式 0 账户; 1 二维码
  const [loginStatus, setLoginStatus] = useState(0);

  // 获取登录方式
  const getLoginStatus = (code) => {
    setLoginStatus(code);
  };

  return (
    <>
      {/* 扫码登录 */}
      <QRrelation
        loginStatus={loginStatus}
        getLoginStatus={getLoginStatus}
      />
      {/* 普通登录以及二维码登录 */}
      {loginStatus === 0 ? <CommonLogin /> : <QRLogin />}
    </>
  );
};

export default Login;
