import React, { Suspense } from 'react';
import { Redirect } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import PageLoading from '@/components/PageLoading';
import { noLeftSidePath } from '@/config/path';

const lazy = CusComponent => <Suspense fallback={<PageLoading />}>{CusComponent}</Suspense>;
const RedirectTo = path => <Redirect to={path || `${noLeftSidePath}/accountSet`} />;
// 个人账号设置页面
const AccountSetPage = React.lazy(() => import('../../views/dashboard/accountSet'));

export const DashboardLayoutRoutes = [
  {
    path: `${noLeftSidePath}/set`,
    name: '账户设置',
    component: props => lazy(<AccountSetPage {...props} />),
  },
  {
    path: '*',
    name: '404',
    component: () => RedirectTo(),
  },
];

export const otherPath = [];
