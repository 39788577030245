import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { Switch } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import openNewPage from '@/utils/openNewPage';
import styles from './index.module.less';
import HeaderComponent from '../commonComponent/Header/DashboardHeader';
import BreadCrumbComponent from '../commonComponent/BreadCrumb';

const { Header, Content } = Layout;

const UserLayout = (props) => {
  const {
    location,
    chidrenRoutes,
  } = props;

  useEffect(() => {
    if (!window.localStorage.getItem('token')) {
      openNewPage('/', '_self');
    }
  });

  return (
    <div className={styles.container}>
      <Layout>
        <Header>
          <HeaderComponent />
        </Header>
        <Content>
          <BreadCrumbComponent />
          <div className={styles.contentContainer}>
            <Switch location={location}>
              {renderRoutes(chidrenRoutes)}
            </Switch>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default UserLayout;
