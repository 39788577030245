import React, { useState, useEffect } from 'react';
import {
  Breadcrumb,
  Icon,
} from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { MainLayoutRoutes } from '@/router/mainLayout';
import { DashboardLayoutRoutes } from '@/router/dashboardLayout';
import styles from './index.module.less';

const BreadcrumbComponent = (props) => {
  const {
    history,
    firstBeforeIconStyle,
  } = props;

  const {
    pathname,
  } = window.location;
  // 当前路由面包屑导航数组
  const [currentRouteArr, setCurrentRouteArr] = useState([]);

  useEffect(() => {
    const AllRoutes = [
      ...DashboardLayoutRoutes,
      ...MainLayoutRoutes,
    ];
    // 遍历路由——获取一条线匹配路径
    const getLineRoutes = (routes, arr) => {
      if (routes.length) {
        const routesTree = routes.filter(item => item.path !== '/' && item.path !== '*' && window.location.pathname.indexOf(item.path) !== -1);
        if (routesTree[0]) {
          // 同行并级展示的页面
          routesTree.forEach(item => {
            arr.push({
              path: item.path,
              name: item.name,
              isClick: !(item.routes && item.routes.length),
            });
          });
          // 父级无页面，子级有页面
          if (routesTree[0].routes && routesTree[0].routes.length) {
            getLineRoutes(routesTree[0].routes, arr);
          }
        }
      }
      return arr;
    };
    const routeLine = getLineRoutes(AllRoutes, []);
    setCurrentRouteArr(routeLine);
  }, [pathname]);

  // 跳转
  const jumpModel = (path) => {
    history.push({
      pathname: path,
      state: {
        pageNumber: 1,
        pageSize: 10
      }
    })
  };

  // 生成面包屑导航
  const produceBreadItem = (data) => {
    return data.map((item, index) => (
      <Breadcrumb.Item key={item.path}>
        {item.isClick ? (
          // <a href={item.path}>{item.name}</a>
          // <span onClick={() => { jumpModel(item.path); }} className={styles.canclick}>{item.name}</span>
          index === data.length - 1 ? <span>{item.name}</span> : <span onClick={() => { jumpModel(item.path); }} className={styles.canclick}>{item.name}</span>
        ) : (
          <span>{item.name}</span>
        )}
      </Breadcrumb.Item>
    ));
  };

  // 第一个 icon 默认样式
  const breadBeforeIconStyle = {
    marginLeft: '23px',
    marginRight: '8px',
    color: '#5B5B5B',
    ...firstBeforeIconStyle,
  };

  return (
    <div className={styles.breadCrumbContainer}>
      <Icon type="right" style={breadBeforeIconStyle} />
      <Breadcrumb>
        {/* {currentRouteArr.length && (
          <Breadcrumb.Item style={breadBeforeIconStyle}>&nbsp;</Breadcrumb.Item>
        )} */}
        {produceBreadItem(currentRouteArr)}
      </Breadcrumb>
    </div>
  );
};

BreadcrumbComponent.defaultProps = {
  firstBeforeIconStyle: {},
};
BreadcrumbComponent.propTypes = {
  firstBeforeIconStyle: PropTypes.object,
};

export default withRouter(BreadcrumbComponent);
