import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Icon,
  Checkbox,
  Button,
  message,
} from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
  phoneReg,
  wordAndNumReg,
} from '@/utils/reg';
import styles from '../index.module.less';

const FormItem = Form.Item;

let timer = null;

const FormInput = (props) => {
  const {
    form: {
      getFieldDecorator,
      getFieldsValue,
      validateFields,
    },
    loginByPassword,
    loginByCode,
    getLoginCheckCode,
    status,
  } = props;

  // 校验手机号判错
  const [phone] = useState('');
  // 校验登录密码判错
  const [pwd] = useState('');

  // 手机验证码倒计时
  const [time, setTime] = useState(60);

  // 显示隐藏密码
  const [showCode, setShowCode] = useState(false);

  // 获取手机验证码
  const getCheckCode = () => {
    // 调个接口
    getLoginCheckCode({
      phone: getFieldsValue().phone,
    }, () => {
      setTime(59);
    });
  };

  useEffect(() => {
    if (time !== 60) {
      timer = setTimeout(() => {
        setTime(time === 1 ? 60 : (time - 1));
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [time]);

  // 显示隐藏密码
  const showPassword = () => {
    setShowCode(!showCode);
  };

  // 登录
  const loginApply = () => {
    validateFields((err, values) => {
      if (!err) {
        if (!phoneReg.test(values.phone) || !values.phone) {
          message.warning('请填写正确的手机号!');
          return;
        }
        const fetchData = async () => {
          if (status === 0) {
            if (!wordAndNumReg.test(values.password) || !values.password) {
              message.warning('密码长度8-20位，包含数字以及字母!');
              return;
            }
            await loginByPassword({
              ...values,
            });
          } else {
            await loginByCode({
              ...values,
            });
          }
        };
        fetchData();
      }
    });
  };

  return (
    <Form className={styles.submitForm}>
      <FormItem style={{ marginBottom: '16px' }}>
        {getFieldDecorator('phone', {
          // rules: [
          //   {
          //     required: true,
          //     message: '请输入手机号',
          //   },
          //   {
          //     validator: (rule, value, callback) => {
          //       if (!value) {
          //         setPhone('');
          //         callback();
          //       }
          //       if (phoneReg.test(value)) {
          //         setPhone('');
          //         callback();
          //       } else {
          //         setPhone('请输入正确的手机号');
          //         callback('请输入正确的手机号');
          //       }
          //     },
          //   },
          // ],
        })(
          <Input
            className={phone ? styles.errorColor : styles.normalColor}
            suffix={<Icon type="user" />}
            placeholder="请输入手机号进行登录"
            onPressEnter={loginApply}
          />,
        )}
      </FormItem>
      {status === 0 ? (
        <FormItem style={{ marginBottom: '24px' }}>
          {getFieldDecorator('password', {
            // rules: [
            //   {
            //     required: true,
            //     message: '请填写密码',
            //   },
            //   {
            //     validator: (rule, value, callback) => {
            //       if (!value) {
            //         setPwd('');
            //         callback();
            //       }
            //       if (wordAndNumReg.test(value)) {
            //         setPwd('');
            //         callback();
            //       } else {
            //         setPwd('密码长度8-20位，包含数字以及字母');
            //         callback('密码长度8-20位，包含数字以及字母');
            //       }
            //     },
            //   },
            // ],
          })(
            <Input
              type={showCode ? 'text' : 'password'}
              className={pwd ? styles.errorColor : styles.normalColor}
              suffix={(
                <Icon
                  onClick={showPassword}
                  type={!showCode ? 'eye-invisible' : 'eye'}
                />
              )}
              placeholder="请输入密码"
              onPressEnter={loginApply}
            />,
          )}
        </FormItem>
      ) : (
        <FormItem
          className={classnames(styles.checkCodeFormItem, styles.hasButtonAddonAfter)}
          style={{
            marginTop: '24px',
            marginBottom: '15px'
          }}
        >
          {getFieldDecorator('code', {
            // rules: [
            //   {
            //     required: true,
            //     message: '请输入手机验证码',
            //   },
            // ],
          })(
            <Input
              className={pwd ? styles.errorColor : styles.normalColor}
              addonAfter={
                <Button
                  disabled={
                    time !== 60
                    || !getFieldsValue().phone
                    || !phoneReg.test(getFieldsValue().phone)
                  }
                  type={
                    time !== 60
                    || !getFieldsValue().phone
                    || !phoneReg.test(getFieldsValue().phone) ? 'default' : 'primary'
                  }
                  className={
                    time !== 60
                    || !getFieldsValue().phone
                    || !phoneReg.test(getFieldsValue().phone) ? '' : styles.enable
                  }
                  onClick={getCheckCode}
                >
                  获取手机验证码
                </Button>
              }
              placeholder="请输入手机验证码"
              onPressEnter={loginApply}
            />,
          )}
        </FormItem>
      )}
      {status === 1 && (
        <div className={styles.checkCodeTip}>
          {(status === 1 && time !== 60) && `${time}s后，重新发送验证码！`}
        </div>
      )}
      {status === 0 && (
        <div className={styles.codeRealtion}>
          <div className={styles.rememberCode}>
            <Checkbox style={{ marginRight: '9px' }} /> 记住密码
          </div>
          <a href="#123" className={styles.forgetCode}>
            忘记密码？
          </a>
        </div>
      )}
      <div className={styles.loginButton}>
        <Button type="primary" onClick={loginApply}>登录 </Button>
      </div>
    </Form>
  );
};

const FormInputForm = Form.create({ name: 'normal_login' })(FormInput);

FormInputForm.defaultProps = {
  status: 0,
};
FormInputForm.propTypes = {
  status: PropTypes.number,
};

export default withRouter(connect(({ loginRegister }) => ({
  loginRegister,
}), (
  {
    loginRegister:
    {
      loginByPassword,
      loginByCode,
      getLoginCheckCode,
    },
  },
) => ({
  loginByPassword,
  loginByCode,
  getLoginCheckCode,
}))(FormInputForm));
