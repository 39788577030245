import React from 'react';
import { Spin } from 'antd';
import styles from './index.module.less';

// loading components from code split
export default () => (
  <div className={styles.pageLoading}>
    <Spin size="large" />
  </div>
);
