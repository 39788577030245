import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.less';

const TriangleLabel = (props) => {
  const {
    color,
  } = props;

  const TriangleLabelColor = {
    background: color || '#5B5AA7',
  };

  return (
    <div className={styles.dropDownSelectIcon}>
      <div style={TriangleLabelColor} />
    </div>
  );
};

TriangleLabel.defaultProps = {
  color: '',
};
TriangleLabel.propTypes = {
  color: PropTypes.string,
};

export default TriangleLabel;
