import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Icon,
} from 'antd'
// import LoginLabel from '@/assets/img/loginLabel.png';
// import { ReactComponent as LoginLabel } from '@/assets/img/goodLabel.svg';
import { ReactComponent as LoginLabel } from '@/assets/img/goodclasslogo.svg';
import PageLoading from '@/components/PageLoading';
import Login from './login';
import Register from './register';
import Footer from '../commonToolsComponent/Footer';
import { ReactComponent as loginBgTeacherImg } from '@/assets/img/login-bg-teacher-img.svg';
import { ReactComponent as loginBgTeacher } from '@/assets/img/login-bg-teacher.svg';
import { ReactComponent as loginBgScore } from '@/assets/img/login-bg-score.svg';
import { ReactComponent as loginBgClass } from '@/assets/img/login-bg-class.svg';
import styles from './index.module.less';

const CommonPage = (props) => {
  const {
    history,
    location: {
      pathname,
    },
    loginRegister: {
      loading,
    },
  } = props;

  // 用户当前状态 0 登录; 1 注册
  const [userStatus, setUserStatus] = useState(pathname === '/register' ? 1 : 0);

  useEffect(() => {
    setUserStatus(pathname === '/register' ? 1 : 0);
  }, [pathname]);

  // 改变用户状态
  const changeUserStatus = (code) => {
    setUserStatus(code);
    history.push(code === 0 ? '/login' : '/register');
  };

  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (
        <div className={styles.loginContainer}>
          {/* label 图 */}
          <div className={styles.loginBgLine} />
          <Icon className={styles.loginBgTeacherImg} component={loginBgTeacherImg} />
          <Icon className={styles.loginBgScore} component={loginBgScore} />
          <Icon className={styles.loginBgTeacher} component={loginBgTeacher} />
          <Icon className={styles.loginBgClass} component={loginBgClass} />
          <div className={styles.loginLabelFrame}>
            <Icon component={LoginLabel} style={{ fontSize: '100px' }} />
          </div>
          {/* 文本 */}
          <div className={styles.loginLabelText}>
            GoodClass
          </div>
          <div
            className={userStatus === 0 ? styles.loginFormFrame : styles.registerFormFrame}
            // style={{
            //   height: userStatus === 0 ? '423px' : '595px',
            // }}
          >
            {/* 判断登录注册 */}
            {userStatus === 0 ? <Login /> : <Register />}
            <div className={styles.switchLoginOrRegister}>
              <div
                className={userStatus === 0 ? styles.userActiveStatus : styles.userCommonStaus}
                onClick={() => { changeUserStatus(0); }}
              >
                登录
              </div>
              <div
                className={userStatus === 1 ? styles.userActiveStatus : styles.userCommonStaus}
                onClick={() => { changeUserStatus(1); }}
              >
                注册
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default withRouter(connect(({ loginRegister }) => ({
  loginRegister,
}))(CommonPage));
