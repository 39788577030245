import request from '@/service';

// 获取下拉类型字典项
export async function getTypeSelect(params) {
  return request('/teacher-service/dict/get_dict_item_list', {
    method: 'get',
    data: params,
  });
}


// 获取进入教室 url
export async function getEnterUrl(params) {
  return request('/teacher-service/online_room/get_enter_url', {
    method: 'get',
    data: params,
  });
}

// 获取回放url
export async function getPlaybackUrl(params) {
  return request('/teacher-service/online_room/get_playback_url', {
    method: 'get',
    data: params,
  });
}
