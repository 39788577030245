import React from 'react';
// import Logo from '@/assets/img/loginLabel.png';
// import { connect } from 'react-redux';
import LoginLabel from '@/assets/img/goodclasslogo.svg';
import styles from './index.module.less';

const SiderTopIcon = () => {
  // const {
  //   AccountSet: {
  //     userInfo,
  //   },
  // } = props;

  return (
    <div className={styles.siderTopIcon}>
      <img src={LoginLabel} alt="logo" />
      <span>GoodClass</span>
      {/* <span className={styles.orgName}>{userInfo.bindOrg ? userInfo.orgName : '未绑定机构'}</span> */}
    </div>
  );
};

export default SiderTopIcon;
