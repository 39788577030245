import React, { useState } from 'react';
import PropTypes from 'prop-types';
import QRenter from '@/assets/img/QRenter.png';
import SweepCode from '@/assets/img/sweepCode.png';
import PicLogin from '@/assets/img/picLogin.png';
import User from '@/assets/img/user.png';
import styles from '../index.module.less';

const QRrelation = (props) => {
  const {
    loginStatus,
    getLoginStatus,
  } = props;

  // hover 时图片显示隐藏
  const [show, setImgshow] = useState(false);

  // 改变图片显示隐藏状态
  const changeImgShowStatus = (status) => {
    setImgshow(status);
  };

  // 改变登录方式
  const changeLoginStatus = (code) => {
    getLoginStatus(code);
  };

  return (
    <div className={styles.sweepCodeFrame}>
      {show && (
        <div className={styles.sweep}>
          <img
            src={loginStatus === 0 ? SweepCode : User}
            alt={loginStatus === 0 ? '扫码登录' : '账号登录'}
          /> {loginStatus === 0 ? '扫码登录' : '账号登录'}
        </div>
      )}
      <img
        className={styles.QRcodeImg}
        src={loginStatus === 0 ? QRenter : PicLogin}
        alt={loginStatus === 0 ? '扫码登录' : '账号登录'}
        onMouseEnter={() => { changeImgShowStatus(true); }}
        onMouseLeave={() => { changeImgShowStatus(false); }}
        onClick={() => { changeLoginStatus(loginStatus === 0 ? 1 : 0); }}
      />
    </div>
  );
};

QRrelation.defaultProps = {
  loginStatus: 0,
};
QRrelation.propTypes = {
  loginStatus: PropTypes.number,
};

export default QRrelation;
